import { inject }                   from 'aurelia-framework';
import { BooleanOption }            from 'modules/administration/models/boolean-option';
import { BulletinRevisionField }    from 'modules/management/models/bulletin-revision-field';
import { BulletinFieldsRepository } from 'modules/management/specifications/bulletin-fields/services/repository';

@inject(BulletinFieldsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        reference_field: BooleanOption.NO,
    };

    /**
     * Constructor
     *
     * @param bulletinFieldsRepository
     */
    constructor(bulletinFieldsRepository) {
        this.bulletinFieldsRepository = bulletinFieldsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BulletinRevisionField}
     */
    model(code, order) {
        let model = new BulletinRevisionField();

        this.modelDefaults.code  = code;
        this.modelDefaults.order = order;

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.order = {
            type:       'text',
            key:        'order',
            label:      'form.field.order',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.bulletin_field_id = {
            type:         'select2',
            key:          'bulletin_field_id',
            label:        'form.field.description',
            size:         6,
            remoteSource: () => this.bulletinFieldsRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.sheet_number = {
            type:       'number',
            key:        'sheet_number',
            label:      'form.field.sheet-number',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.line = {
            type:       'number',
            key:        'line',
            label:      'form.field.line',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.column = {
            type:       'text',
            key:        'column',
            label:      'form.field.column',
            size:       6,
            required:   false,
            attributes: {
                maxlength: 10,
                readonly:  readonly,
            },
        };

        this.decimal_places = {
            type:       'number',
            key:        'decimal_places',
            label:      'form.field.decimal-places',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.reference_field = {
            type:       'boolean-options-select',
            key:        'reference_field',
            label:      'form.field.reference-field',
            size:       6,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        return [
            [this.code, this.order],
            [this.bulletin_field_id, this.sheet_number],
            [this.line, this.column],
            [this.decimal_places, this.reference_field],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
