import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class RevisionFieldsRepository extends BaseRepository {
    baseUrl             = 'management/specifications/bulletins/revisions/fields';
    bulletinRevisionUrl = 'management/specifications/bulletins/revisions';

    /**
     * Performs a search given some criteria
     *
     * @param bulletinRevisionId
     * @param criteria
     *
     * @returns {*}
     */
    search(bulletinRevisionId, criteria) {
        return this.httpClient.post(`${this.bulletinRevisionUrl}/${bulletinRevisionId}/fields/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param bulletinRevisionId
     * @param data
     *
     * @returns {*}
     */
    create(bulletinRevisionId, data) {
        return this.httpClient.post(`${this.bulletinRevisionUrl}/${bulletinRevisionId}/fields`, { ...data, bulletin_revision_id: bulletinRevisionId });
    }

    /**
     * Reorders fields
     *
     * @param bulletinRevisionId
     * @param data
     *
     * @returns {*}
     */
    updateOrder(bulletinRevisionId, data) {
        return this.httpClient.put(`${this.bulletinRevisionUrl}/${bulletinRevisionId}/fields/reorder`, data);
    }
}
