import { bindable, inject }            from 'aurelia-framework';
import { BaseListViewModel }           from 'base-list-view-model';
import { BulletinRevisionsRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/repository';
import { AppContainer }                from 'resources/services/app-container';

@inject(AppContainer, BulletinRevisionsRepository)
export class ListBulletinRevisions extends BaseListViewModel {

    listingId = 'management-specifications-bulletins-revisions-component-listing';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable datatable;
    @bindable bulletin;
    @bindable model;
    @bindable lastRevisionData;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.revisions.manage',
            'management.specifications.bulletins.revisions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search: () => this.searchLastRevision(),
            },
            actionsContextMenu:  false,
            showOptionsDropdown: false,
            showFilter:          false,
            showResultsPerPage:  false,
            showPagination:      false,
            showInfo:            false,
            actions:             [
                {
                    icon:    'icon-copy',
                    action:  () => this.copyToModel(),
                    label: 'form.button.copy',
                },
            ],
            options:             [],
            buttons:             [],
            selectable:          false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:       'last_revision_number',
                    name:       'bulletin_revisions.revision_number',
                    title:      'form.field.revision-number',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:            'effective_date',
                    name:            'bulletin_revisions.effective_date',
                    title:           'form.field.effective-date',
                    searchable:      false,
                    orderable:       false,
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:       'bulletin_model',
                    name:       'bulletin_revisions.bulletin_model',
                    title:      'form.field.bulletin-model',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:       'norm_edition',
                    name:       'bulletin_revisions.norm_edition',
                    title:      'form.field.norm-edition',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:       'test_registered_in_platform_option',
                    name:       'boolean_options.name',
                    title:      'form.field.test-registered-in-platform',
                    searchable: false,
                    orderable:  false,
                    type:       'label',
                },
                {
                    data:       'tests_quantity',
                    name:       'bulletin_revisions.tests_quantity',
                    title:      'form.field.tests-quantity',
                    searchable: false,
                    orderable:  false,
                },
            ],
        };
    }

    /**
     * Copies the last revision data to the model
     */
    copyToModel() {
        this.model.assign(this.lastRevisionData);
    }

    /**
     * Performs a search given some criteria
     *
     *
     * @returns {*}
     */
    async searchLastRevision() {
        const response = await this.repository.searchLastRevision(this.bulletin);

        this.lastRevisionData = response.data[0];

        return response;
    }
}
