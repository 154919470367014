import { DialogService }               from 'aurelia-dialog';
import { bindable, inject }            from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { PLATFORM }                    from 'aurelia-pal';
import { BaseListViewModel }           from 'base-list-view-model';
import { CreateBulletinRevisionModal } from 'modules/management/specifications/bulletins/bulletin-revisions/create';
import { EditBulletinRevisionModal }   from 'modules/management/specifications/bulletins/bulletin-revisions/edit';
import { BulletinRevisionsRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/repository';
import { ViewBulletinRevisionModal }   from 'modules/management/specifications/bulletins/bulletin-revisions/view';
import { AppContainer }                from 'resources/services/app-container';
import { Downloader }                  from 'resources/services/downloader';

@inject(AppContainer, BulletinRevisionsRepository, I18N, Downloader, DialogService)
export class ListBulletinRevisions extends BaseListViewModel {

    listingId = 'management-specifications-bulletins-revisions-listing';

    @bindable headerTitle    = null;
    @bindable newRecordRoute = 'management.specifications.bulletins.revisions.create';
    @bindable repository;
    @bindable datatable;
    @bindable bulletin;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, dialogService) {
        super(appContainer);

        this.i18N          = i18N;
        this.repository    = repository;
        this.downloader    = downloader;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.revisions.manage',
            'management.specifications.bulletins.revisions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.bulletin = params.model;
        this.readonly = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:               {
                action:  (row) => this.openModal(row, ViewBulletinRevisionModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletins.revisions.manage', 'management.specifications.bulletins.revisions.view']),
            },
            edit:               {
                action:  (row) => this.openModal(row, EditBulletinRevisionModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.bulletins.revisions.manage', 'management.specifications.bulletins.revisions.edit']),
            },
            destroy:            !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.bulletins.revisions.manage', 'management.specifications.bulletins.revisions.delete']),
            destroySelected:    !this.readonly,
            actionsContextMenu: true,
            actions:            [],
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.bulletin-revisions'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletins.revisions.manage', 'management.specifications.bulletins.revisions.view']),
                },
            ],
            buttons:            [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.bulletins.revisions.manage', 'management.specifications.bulletins.revisions.create']),
                    action:           () => this.openModal(this, CreateBulletinRevisionModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'revision_number',
                    name:  'bulletin_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:            'effective_date',
                    name:            'bulletin_revisions.effective_date',
                    title:           'form.field.effective-date',
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:            'closed_date',
                    name:            'bulletin_revisions.closed_date',
                    title:           'form.field.end-date',
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:  'bulletin_model',
                    name:  'bulletin_revisions.bulletin_model',
                    title: 'form.field.bulletin-model',
                },
                {
                    data:  'norm_edition',
                    name:  'bulletin_revisions.norm_edition',
                    title: 'form.field.norm-edition',
                },
                {
                    data:  'test_registered_in_platform',
                    name:  'boolean_options.name',
                    title: 'form.field.test-registered-in-platform',
                    type:  'label',
                },
                {
                    data:  'tests_quantity',
                    name:  'bulletin_revisions.tests_quantity',
                    title: 'form.field.tests-quantity',
                },
                {
                    data:       'bulletin_model_file_submitted',
                    name:       'bulletin_model_file_submitted',
                    title:      'form.field.model-file',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/specifications/bulletins/custom-listing-cells/bulletin-model-file-submitted/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'bulletin_revisions.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.bulletin.id, criteria);
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        if (selectedRows) {
            filterModel.selectedRows = selectedRows;
        }

        this.repository.export(this.bulletin.id, filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }
}
