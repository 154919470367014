import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BulletinRevisionsRepository extends BaseRepository {
    baseUrl     = 'management/specifications/bulletins/revisions';
    bulletinUrl = 'management/specifications/bulletins';

    /**
     * Performs a search given some criteria
     *
     * @param bulletinId
     * @param criteria
     *
     * @returns {*}
     */
    search(bulletinId, criteria) {
        return this.httpClient.post(`${this.bulletinUrl}/${bulletinId}/revisions/search`, criteria);
    }

    /**
     * Performs a search given some criteria
     *
     * @param bulletinId
     *
     * @returns {*}
     */
    searchLastRevision(bulletinId) {
        return this.httpClient.get(`${this.bulletinUrl}/${bulletinId}/revisions/search-last-revision`);
    }

    /**
     * Creates a record
     *
     * @param bulletinId
     * @param data
     *
     * @returns {*}
     */
    create(bulletinId, data) {
        return this.httpClient.post(`${this.bulletinUrl}/${bulletinId}/revisions`, { ...data, bulletin_id: bulletinId });
    }

    /**
     * Exports a search given some criteria
     *
     * @param bulletinId
     * @param criteria
     *
     * @returns {*}
     */
    export(bulletinId, criteria) {
        return this.httpClient.rawPost(`${this.bulletinUrl}/${bulletinId}/revisions/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Fetches the next field code number
     *
     * @param bulletinRevisionId
     * @return {*}
     */
    fetchNextFieldCodeNumber(bulletinRevisionId) {
        return this.httpClient.get(`${this.baseUrl}/${bulletinRevisionId}/next-code-number`);
    }

    /**
     * Fetches the next field order number
     *
     * @param bulletinRevisionId
     * @return {*}
     */
    fetchNextFieldOrderNumber(bulletinRevisionId) {
        return this.httpClient.get(`${this.baseUrl}/${bulletinRevisionId}/next-order-number`);
    }

}
